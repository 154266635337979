<template>
  <v-form @submit.prevent="submitResponse">
    <p>
      a) Graphite is a crystalline form of carbon and is commonly referred to as “pencil lead”. If
      the “lead” in a pencil consists of
      <number-value :value="percentage" />% graphite (elemental carbon) and you have a “lead” that
      weighs <number-value :value="grams" /> g , what mass of graphite is in that sample (in grams)?
    </p>
    <calculation-input v-model="inputs.input1" append-text="grams" />
    <br />
    <p>
      b) A human body consists of 18% elemental carbon. Assuming you have a man that weighs
      <number-value :value="kg" /> kg, how many pencil “leads” worth of carbon are in his body?
    </p>
    <calculation-input v-model="inputs.input2" />
  </v-form>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';

export default {
  name: 'CalculateGramPercentages',
  components: {NumberValue, CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
    };
  },
  computed: {
    percentage() {
      return this.taskState.getValueBySymbol('percentage').content;
    },
    grams() {
      return this.taskState.getValueBySymbol('grams').content;
    },
    kg() {
      return this.taskState.getValueBySymbol('kg').content;
    },
  },
};
</script>

<style scoped></style>
